import React from 'react';
import styled from 'styled-components';

import { Paper } from 'atoms';
import { TeaserSegment, LinkButton } from 'molecules';
import { Heading, Paragraph } from 'Typography';
import { useTranslation } from 'react-i18next';

const DocumentLeaflet = styled(Paper)`
  padding: 1.334rem 1.6rem;
  display: grid;
  grid-gap: 1.384rem;
`;

const DocumentTeaser = ({ newTab, ...args }) => {
  const { t } = useTranslation();
  return (
    <DocumentLeaflet primary {...args}>
      <TeaserSegment>
        <Heading type="h5">{t('document_teaser_heading')}</Heading>
        <Paragraph>{t('document_teaser_link')}</Paragraph>
        <LinkButton primary marginTop="0.5rem">
          <a
            href="https://www.yousty.ch/de-CH/vorlagenundvideos"
            target={newTab && '_blank'}
            rel={newTab && 'noopener noreferrer'}
          >
            {t('document_teaser_button_text')}
          </a>
        </LinkButton>
      </TeaserSegment>
      <TeaserSegment
        type="pdf"
        content={{
          headerText: t('document_teaser_pdf_header'),
          links: [
            {
              to:
                'https://yousty-switzerland.s3.eu-west-1.amazonaws.com/pdfs/Arbeitsblatt_Interessen_und_Sta%CC%88rken.pdf',
              text: t('document_teaser_pdf_item1'),
            },
            {
              to:
                'https://yousty-switzerland.s3.eu-west-1.amazonaws.com/pdfs/Musterdokument_Bewerbungsschreiben_TPA.pdf',
              text: t('document_teaser_pdf_item2'),
            },
            {
              to:
                'https://yousty-switzerland.s3.eu-west-1.amazonaws.com/pdfs/Musterdokument_Lebenslauf_TPA.pdf',
              text: t('document_teaser_pdf_item3'),
            },
          ],
        }}
        grid
      />
      <TeaserSegment
        type="media"
        content={{
          headerText: t('document_teaser_media_header'),
          links: [
            {
              to: 'https://www.youtube.com/watch?v=sPM7AbtCUmg',
              text: t('document_teaser_media_item1'),
            },
            {
              to: 'https://www.youtube.com/watch?v=Gz8bAiyqLEY',
              text: t('document_teaser_media_item2'),
            },
            {
              to: 'https://www.youtube.com/watch?v=SwUP0YREjxc',
              text: t('document_teaser_media_item3'),
            },
          ],
        }}
        grid
      />
    </DocumentLeaflet>
  );
};

export default DocumentTeaser;
